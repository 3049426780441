<template>
  <div class="">
    <van-nav-bar title="跑步机上门服务流程" left-arrow  @click-left="$router.go(-1)"/>
    <div class="door">
     <h2>跑步机上门服务流程</h2>
     <div class="wrap">
      <img :src="imgs.door" alt="">
      <div class="nr">
        <h3>上门服务注意事项</h3>
        <p>1、上门维修服务目前仅限跑步机，其他品类产品暂不支持；</p>
        <p>2、跑步机产品的使用问题，请您参考说明书或电话咨询400-000-6666客服；</p>
        <p>3、产品出现质量问题需要维修，请您联系400-000-6666客服进行报修，报修后请保持联系电话畅通，工程师会尽快与您联系，预约上门检测/维修时间；</p>
        <p>4、跑步机保外维修，除收取维修部件的费用外，还需要收取上门费，需经您同意并支付费用后，才会安排师傅上门检测，并给出最终的报价，如果您拒绝付费，Amazfit售后将无法直接提供上门维修服务，仅可提供售后维修指导。</p>
      </div>
     </div>
    </div>
  </div>
</template>

<script>
import { CDN_URL } from '../../config'
export default {
  name: 'door',
  data () {
    return {
      imgs: {
        door: CDN_URL + '/images/service/door.png'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.door {
  text-align: center;
  padding-bottom: 10vw;
  h2 {
    font-size: 6.5vw;
    color: #000;
    text-align: center;
  }
  img {
    width: 62vw;
  }
  .nr {
    width: 86vw;
    margin: 0 auto;
    margin-top: 5vw;
    background: #F2F2F2;
    padding: 4vw;
    text-align: left;
    border-radius: 2vw;
    box-sizing: border-box;
    h3 {
      font-size: 4vw;
      color: #333333;
    }
    p {
      font-size: 2.9vw;
      color: #666666;
      line-height: 1.6;
      margin: 0;
    }
  }
}
</style>
